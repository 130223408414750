<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              v-on="on"
              color="success"
              @click.stop="newDialog = true"
            >
              new
            </v-btn>
          </template>
          <span>Create new medic frequency</span>
        </v-tooltip>
        <InfoTypesNew
          :newDialog="newDialog"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" @click="lists()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-card flat rounded="0">
          <v-card-text>
            <v-data-table
              :loading="loading"
              loader-height="2"
              :headers="headers"
              :items="infoTypeslist"
              :items-per-page="10"
              :footer-props="{ itemsPerPageOptions: [10, 15, 20] }"
            >
              <template v-slot:item.created="{ item }">
                {{ item.created | myDating }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  @click.stop="$set(viewDialog, item.id, true)"
                  text
                  x-small
                  color="primary"
                >
                  view
                </v-btn>
                <InfoTypesView
                  v-if="viewDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :viewDialog="viewDialog[item.id]"
                  @closeView="closeView"
                />

                <v-btn
                  @click.stop="$set(editDialog, item.id, true)"
                  text
                  x-small
                  color="success"
                >
                  edit
                </v-btn>
                <InfoTypesEdit
                  v-if="editDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :editDialog="editDialog[item.id]"
                  @closeEdit="closeEdit"
                  @submitEdit="submitEdit"
                />

                <v-btn
                  text
                  x-small
                  color="pink"
                  @click.stop="$set(removeDialog, item.id, true)"
                  >DELETE
                </v-btn>
                <InfoTypesRemove
                  v-if="removeDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :removeDialog="removeDialog[item.id]"
                  @closeRemove="closeRemove"
                  @submitRemove="submitRemove"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
      <v-snackbar
        :color="snack.color"
        v-model="snack.bar"
        :multi-line="snack.multiLine"
      >
        {{ snack.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import InfoTypesNew from "../../../components/medics/medic_route/newone.vue";
import InfoTypesView from "../../../components/medics/medic_route/view.vue";
import InfoTypesEdit from "../../../components/medics/medic_route/edit.vue";
import InfoTypesRemove from "../../../components/medics/medic_route/remove.vue";
export default {
  components: { InfoTypesNew, InfoTypesView, InfoTypesEdit, InfoTypesRemove },
  data() {
    return {
      loading: false,
      newDialog: false,
      viewDialog: {},
      editDialog: {},
      removeDialog: {},
      breadcrumb: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "admin.dash" },
        },
        {
          text: "Settings",
          disabled: true,
        },
        {
          text: "Medic Route",
          disabled: true,
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      headers: [
        { text: "name", value: "name" },
        { text: "created", value: "created" },
        { text: "updated", value: "updated" },
        { text: "actions", value: "actions" },
      ],
      infoTypeslist: [],
    };
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let self = this;
      Restful.general.medicRoute
        .list()
        .then((response) => {
          console.log(response.data);
          this.infoTypeslist = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.general.medicRoute
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.newDialog = p.state;
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.general.medicRoute
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      let self = this;
      Restful.general.medicRoute
        .remove(p.id)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
